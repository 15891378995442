import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { useParams, useNavigate } from 'react-router-dom';
import commonS from 'styles/common.module.less';
import { Spin, Button, Tag, Modal, Switch, Checkbox, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ChannelType, ChannelActivationStatus, EChannelActiveStatus } from 'types/common';
import ClockIcon from 'assets/common/clockIcon.svg';
import GlobeIcon from 'assets/common/globeIcon.svg';
import UserIcon from 'assets/common/users.svg';
import UserAddIcon from 'assets/common/userAdd.svg';
import ExternalLinkIcon from 'assets/common/externalLinkIcon.svg';
import OnHoldImg from 'assets/operation/sendFeedback.png';
import ApproveImg from 'assets/operation/approve.png';
import { PATH } from 'constants/path';
import { GOOGLE_ADMIN_URL } from 'constants/common';
import useChannelDetail from 'hooks/useChannelDetail';
import BasicInformation from 'components/channel/BasicInformation';
import DetailInformation from 'components/channel/DetailInformation';
import DetailInformationForKlarity from 'components/channel/DetailInformationForKlarity';
import ServiceConfig from 'components/channel/ServiceConfig';
import { updateChannelDetail, updateChannelGoLive, updateChannelTop, updateChannelCancelTop, updateChannelActivation, updateChannelVisible } from 'api/operation';
import { getTimeStr } from 'utils/calendar';
import dayjs from 'dayjs';

const Page = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [modal, contextHolder] = Modal.useModal();
    const [currentId, setCurrentId] = React.useState<number | undefined>(id ? parseInt(id, 10) : undefined);
    const [channelDetail, loading, run] = useChannelDetail(currentId);
    const isEdit = !!id;
    const [showAddToUpcoming, setShowAddToUpcoming] = React.useState(false);
    const [showActivateChannel, setShowActivateChannel] = React.useState(false);
    const type = channelDetail?.type;
    const isAlive = type?.toLowerCase() === ChannelType.ALIVE.toLowerCase();
    const isUpcoming = type?.toLowerCase() === ChannelType.UPCOMING.toLowerCase();
    const isComplete = channelDetail?.status === 'Complete';
    const isFeature = !!channelDetail?.top;
    const channelName = channelDetail?.name || 'New channel';
    const [confirmChangeActiveStatus, setConfirmChangeActiveStatus] = useState(false);
    const [showChannelActiveStatusModal, setShowChannelActiveStatusModal] = useState(false);
    const [channelIsShown, setChannelIsShown] = useState(false);
    const [channelIsActive, setChannelIsActive] = useState(false);
    const [activationChangeLoading, setActivationChangeLoading] = useState(false);
    const [visibleChangeLoading, setVisibleChangeLoading] = useState(false);

    useEffect(() => {
        if (channelDetail) {
            const { isShow, activeStatus } = channelDetail;
            setChannelIsShown(!!isShow);
            setChannelIsActive(activeStatus === EChannelActiveStatus.ACTIVE);
        }
    }, [channelDetail]);

    const handleChangeActivation = useCallback(async (v: boolean) => {
        if (channelDetail) {
            setActivationChangeLoading(true);
            const { id: channelId } = channelDetail;
            try {
                const res = await updateChannelActivation(channelId, v);

                if (res && !res.error) {
                    setChannelIsActive(v);
                    setConfirmChangeActiveStatus(false);
                    setShowChannelActiveStatusModal(false);
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }

            setActivationChangeLoading(false);
        }
    }, [channelDetail]);

    const handleChangeVisible = useCallback(async (v: boolean) => {
        if (channelDetail) {
            setVisibleChangeLoading(true);
            const { id: channelId } = channelDetail;
            try {
                const res = await updateChannelVisible(channelId, v);

                if (res && !res.error) {
                    setChannelIsShown(v);
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }
            setVisibleChangeLoading(false);
        }
    }, [channelDetail]);

    return (
        <div className={s.wrap}>
            <Spin spinning={loading}>
                <Modal
                    open={showChannelActiveStatusModal}
                    width="750px"
                    closable
                    title={`Set ${channelName} as inactive`}
                    // icon={null}
                    okText="Set as inactive"
                    okButtonProps={{
                        danger: true,
                        loading: activationChangeLoading,
                        disabled: !confirmChangeActiveStatus,
                    }}
                    cancelText="Cancel"
                    onOk={async () => {
                        handleChangeActivation(false);
                    }}
                    onCancel={() => {
                        setConfirmChangeActiveStatus(false);
                        setShowChannelActiveStatusModal(false);
                    }}
                >
                    <div className={s.modalContent}>
                        <p>Set the channel as inactive will have impact on all providers who has been listed on this channel. The channel will be removed from their My Channels and they can no longer get patients from this channel. </p>
                        <div>
                            <Checkbox
                                checked={confirmChangeActiveStatus}
                                onChange={(e) => {
                                    setConfirmChangeActiveStatus(e.target.checked);
                                }}
                            >{`I confirm to set ${channelName} as inactive Get notified when someones posts a comment on a posting.`}
                            </Checkbox>
                        </div>
                    </div>
                </Modal>
                <div className={s.header}>
                    <div className={s.headerTop}>
                        <span
                            onClick={() => {
                                navigate(`${PATH.OPERATION}/${PATH.CHANNEL}?type=management`);
                            }}
                            className={s.leftTopHome}
                        ><ArrowLeftOutlined />
                        </span>
                        <span
                            onClick={() => {
                                navigate(`${PATH.OPERATION}/${PATH.CHANNEL}?type=management`);
                            }}
                            className={s.link}
                        >
                            Back to Channel store management
                        </span>
                        {/* <span className={s.leftTopNav}><RightOutlined /></span>
                        <span className={s.leftTopName}>
                            {channelName}
                        </span> */}
                    </div>
                    <div className={s.headerMid}>
                        <div className={s.left}>
                            <h1 className={s.leftBtm}>
                                <span className={s.title}>
                                    {channelName}
                                    {isAlive && <Tag color="#D1FAE5" className={s.tagLive}>{ChannelType.ALIVE}</Tag>}
                                    {isUpcoming && <Tag color="#FEF3C7" className={s.tagUp}>{ChannelType.UPCOMING}</Tag>}
                                    {channelIsActive ? <Tag color="#DBEAFE" className={`${s.tagActivation} ${s.active}`}>{ChannelActivationStatus.ACTIVE}</Tag> : <Tag color="#F3F4F6" className={`${s.tagActivation} ${s.inactive}`}>{ChannelActivationStatus.INACTIVE}</Tag>}
                                </span>
                            </h1>
                        </div>
                        <div className={s.right}>
                            <div className={s.visibleBtn}>
                                <span>Show in Kiwi</span>
                                <Switch checked={channelIsShown} loading={visibleChangeLoading} onChange={handleChangeVisible} />
                            </div>
                            {
                                channelIsActive ? (
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => {
                                            setShowChannelActiveStatusModal(true);
                                        }}
                                        style={{
                                            marginLeft: '16px',
                                        }}
                                    >
                                        Set as inactive
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => handleChangeActivation(true)}
                                        loading={activationChangeLoading}
                                        style={{
                                            marginLeft: '16px',
                                        }}
                                    >
                                        Set as active
                                    </Button>
                                )
                            }
                            {
                                isAlive && !isFeature &&
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        modal.confirm({
                                            width: '600px',
                                            closable: true,
                                            title: 'Set as a featured channel ',
                                            icon: null,
                                            content: (
                                                <div className={s.modalContent}>
                                                    <p>By setting this channel as a featured channel, it will be prominently displayed on the channel recommendation area for providers. Providers who apply for other channels will be asked if they are also interested in trying out this channel.</p>
                                                    <p> Do you confirm to set this channel as a featured channel?</p>
                                                </div>),
                                            okText: 'Set as Featured',
                                            cancelText: 'Cancel',
                                            onOk: async () => {
                                                const result = await updateChannelTop(currentId!);
                                                if (!result.error) {
                                                    run();
                                                }
                                            },
                                        });
                                    }}
                                    style={{
                                        marginLeft: '16px',
                                    }}
                                >
                                    Set as Featured
                                </Button>
                            }
                            {
                                isAlive && isFeature &&
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        modal.confirm({
                                            width: '600px',
                                            closable: true,
                                            title: 'Remove from Featured Channels',
                                            icon: null,
                                            content: (
                                                <div className={s.modalContent}>
                                                    <p>By removing this channel from the featured channels list, it will no longer be prominently displayed on the channel recommendation area for providers.
                                                        Providers who apply for other channels will no longer be asked if they are interested in trying out this channel.
                                                    </p>
                                                    <p>Do you confirm to remove this channel from the featured channels list?</p>
                                                </div>),
                                            okText: 'Remove from Featured',
                                            cancelText: 'Cancel',
                                            onOk: async () => {
                                                const result = await updateChannelCancelTop(currentId!);
                                                if (!result.error) {
                                                    run();
                                                }
                                            },
                                        });
                                    }}
                                    style={{
                                        marginLeft: '16px',
                                    }}
                                >
                                    Remove from Featured
                                </Button>
                            }
                            {
                                isUpcoming && isComplete &&
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        modal.confirm({
                                            width: '600px',
                                            closable: true,
                                            title: 'Activate the channel',
                                            icon: null,
                                            content: <div className={s.modalContent}>Once the channel is alive, providers will be able to apply for listing. Do you confirm to change status to alive for this channel?</div>,
                                            okText: 'Yes, confirm',
                                            cancelText: 'No, I will check again',
                                            onOk: async () => {
                                                const result = await updateChannelGoLive(currentId!);
                                                if (!result.error) {
                                                    run();
                                                }
                                            },
                                        });
                                    }}
                                    style={{
                                        marginLeft: '16px',
                                    }}
                                >
                                    Go live
                                </Button>
                            }
                            {
                                isUpcoming && !isComplete &&
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        modal.confirm({
                                            width: '600px',
                                            closable: true,
                                            icon: null,
                                            title: 'Add to upcoming channels',
                                            content: <div className={s.modalContent}>Once the channel is alive, providers will be able to apply for listing. Do you confirm to change status to alive for this channel?</div>,
                                            okText: 'Confirm',
                                            cancelText: 'No, I will check agian',
                                            onOk: async () => {
                                                const result = await updateChannelDetail({ complete: true, id: currentId });
                                                if (!result.error) {
                                                    run();
                                                }
                                            },
                                        });
                                    }}
                                    style={{
                                        marginLeft: '16px',
                                    }}
                                >
                                    Add to upcoming channels
                                </Button>
                            }
                        </div>
                    </div>
                    {
                        currentId &&
                        <div className={s.headerBottom}>
                            <div className={s.headerItem}>
                                <img src={ClockIcon} />
                                <span className={s.headerItemValue}>
                                    Date last modified: {getTimeStr(dayjs(channelDetail?.updateTime), 'MMM D, YYYY [at] HH:mm A')}
                                </span>
                            </div>
                            <div
                                className={s.website}
                                onClick={() => {
                                    window.open(channelDetail?.website, '_blank');
                                }}
                            >
                                <img src={GlobeIcon} />
                                <span className={s.websiteValue}>{channelDetail?.website}</span>
                                <img src={ExternalLinkIcon} />
                            </div>
                            {
                                isAlive &&
                                <>
                                    <div className={s.headerItem}>
                                        <img src={UserIcon} />
                                        <span className={s.headerItemValue}>
                                            List Providers: {channelDetail?.listedCount || 0}
                                        </span>
                                    </div>
                                    <div className={s.headerItem}>
                                        <img src={UserAddIcon} />
                                        <span className={s.headerItemValue}>
                                            New Applications: {channelDetail?.unProcessedCount || 0}
                                        </span>
                                    </div>
                                </>
                            }
                            {
                                isUpcoming &&
                                <div className={s.headerItem}>
                                    <img src={UserIcon} />
                                    <span className={s.headerItemValue}>
                                        On the waitlist: {channelDetail?.waitingCount || 0}
                                    </span>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className={s.content}>
                    <div className={s.module}>
                        <BasicInformation
                            channelId={currentId}
                            editable={!isEdit}
                            onChange={(channel) => {
                                setCurrentId(channel.id);
                                if (currentId) {
                                    run();
                                }
                            }}
                            initValue={{
                                name: channelDetail?.name,
                                logo: channelDetail?.logo,
                                website: channelDetail?.website,
                                intro: channelDetail?.intro,
                                rectangleLogo: channelDetail?.rectangleLogo,
                                payPlanType: channelDetail?.payPlanType,
                                kiwiPrice: channelDetail?.kiwiPrice,
                                originalPrice: channelDetail?.originalPrice,
                                billingCycle: channelDetail?.billingCycle,
                                subscriptionPriceId: channelDetail?.subscriptionPriceId,
                                freeTrialDays: channelDetail?.freeTrialDays,
                                reviewRanking: channelDetail?.reviewRanking || undefined,
                                reviewAvailable: !!(channelDetail?.reviewRanking),
                                klarityPlanLevel: channelDetail?.klarityPlanLevel ? [channelDetail?.klarityPlanLevel] : [0],
                            }}
                        />
                    </div>
                    <div className={s.module}>
                        <DetailInformation
                            channelId={currentId}
                            editable={!isEdit}
                            onChange={(channel) => {
                                setCurrentId(channel.id);
                                if (currentId) {
                                    run();
                                }
                            }}
                            initValue={{
                                name: channelDetail?.name,
                                channelDesc: channelDetail?.channelDesc,
                                economics: channelDetail?.economics,
                                caseStudy: channelDetail?.caseStudy,
                                news: channelDetail?.news,
                                tagList: channelDetail?.tagList || [],
                                systemEmail: channelDetail?.systemEmail,
                            }}
                        />
                    </div>
                    <div className={s.module}>
                        <DetailInformationForKlarity
                            channelId={currentId}
                            open={channelDetail?.sameAsKiwi === false}
                            editable={!isEdit}
                            onChange={(channel) => {
                                setCurrentId(channel.id);
                                if (currentId) {
                                    run();
                                }
                            }}
                            initValue={{
                                name: channelDetail?.name,
                                klarityChannelDesc: channelDetail?.klarityChannelDesc || channelDetail?.channelDesc,
                                klarityEconomics: channelDetail?.klarityEconomics || channelDetail?.economics,
                                klarityCaseStudy: channelDetail?.klarityCaseStudy || channelDetail?.caseStudy,
                                klarityNews: channelDetail?.klarityNews || channelDetail?.news,
                                klarityTagList: channelDetail?.klarityTagList || channelDetail?.tagList || [],
                                klaritySystemEmail: channelDetail?.klaritySystemEmail || channelDetail?.systemEmail,
                            }}
                        />
                    </div>
                    <div className={s.module}>
                        <ServiceConfig
                            channelId={currentId}
                            editable={!channelDetail?.serviceConfig || !isEdit}
                            onChange={(channel) => {
                                setCurrentId(channel.id);
                                if (currentId) {
                                    run();
                                }
                            }}
                            initValue={{
                                name: channelDetail?.name,
                                serviceConfig: channelDetail?.serviceConfig,
                            }}
                        />
                    </div>
                </div>

                <Modal
                    title="Add to upcoming channels"
                    closable
                    className={commonS.modalFixHeightWrap}
                    width="660px"
                    destroyOnClose
                    onCancel={() => {
                        setShowAddToUpcoming(false);
                    }}
                    footer={
                        <div className={s.footerWrap}>
                            <Button onClick={() => {
                                setShowAddToUpcoming(false);
                                navigate(`${PATH.OPERATION}/${PATH.PROVIDER}`);
                            }}
                            >
                                Back to Providers
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setShowAddToUpcoming(false);
                                    window.open(`mailto:${channelDetail?.name}`);
                                }}
                            >
                                Open Gmail
                            </Button>
                        </div>
                    }
                    open={showAddToUpcoming}
                >
                    <div className={s.onHoldWrap}>
                        <div className={s.imgWrap}>
                            <img src={OnHoldImg} />
                        </div>
                        <div className={s.title}>Send feedback to provider </div>
                        <div className={s.subTitle}>Once the channel is add to upcoming channels, other Ops team member will be able to edit this channel. Do you confirm to create this channel?</div>
                    </div>
                </Modal>
                <Modal
                    title="Application approve"
                    closable
                    className={commonS.modalFixHeightWrap}
                    width="660px"
                    destroyOnClose
                    footer={
                        <div className={s.footerWrap}>
                            <Button onClick={() => {
                                setShowAddToUpcoming(false);
                                navigate(`${PATH.OPERATION}/${PATH.PROVIDER}`);
                            }}
                            >
                                Back to Providers
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setShowAddToUpcoming(false);
                                    window.open(GOOGLE_ADMIN_URL, '_blank');
                                }}
                            >
                                Open Google Admin Console
                            </Button>
                        </div>
                    }
                    onCancel={() => {
                        setShowAddToUpcoming(false);
                    }}
                    open={showActivateChannel}
                >
                    <div className={s.onHoldWrap}>
                        <div className={s.imgWrap}>
                            <img src={ApproveImg} />
                        </div>
                        <div className={s.title}>
                            application has been approved
                        </div>
                        <div className={s.subTitle}>Please create a Kiwi Health Gmail account for the provider. After creating the BP email account, please come back to this page and input the email address in the Ops Portal.</div>
                    </div>
                </Modal>
                {contextHolder}
            </Spin>
        </div>
    );
};

export default Page;
