import React, { useEffect } from 'react';
import s from './s.module.less';

interface IProps {
    value: number;
}

const Progress = ({
    value,
}: IProps) => {
    const radius = 14;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference * (1 - value / 100);
    return (
        <div className={s.progressContainer}>
            <svg width="32" height="32" viewBox="0 0 32 32">
                <circle className={s.progressCircle} cx="16" cy="16" r={radius} />
                <circle
                    cx="16"
                    cy="16"
                    r={radius}
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    fill="none"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                />
            </svg>
            <div className={s.progressText}>{value}%</div>
        </div>
    );
};

export default Progress;
