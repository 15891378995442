import React, { useCallback, useState } from 'react';
import s from './s.module.less';
import YouTubePlayer from 'components/YoutubePlayer';
import { Button, Modal } from 'antd';
import Image1 from './images/1.png';
import { ONBOARDING_WATCHED_INTRO_VIDEO } from 'constants/common';

interface IProps {
    setOnboardingPopupStep: (step: number) => void;
    onClosePopup: () => void;
    setHasWatchedVideo: (value: boolean) => void;
}

const Intro = ({
    setOnboardingPopupStep,
    onClosePopup,
    setHasWatchedVideo,
}: IProps) => {
    const [play, setPlay] = useState(false);

    const handleNext = useCallback(() => {
        setOnboardingPopupStep(2);
    }, []);

    return (
        <div className={s.wrap}>
            <div className={s.playerBox}>
                <div className={s.closeIcon} onClick={onClosePopup} />
                {
                    play ? (
                        <YouTubePlayer
                            playerWrapClassName={s.playerWrapper}
                            videoId="reb_QeK5TVw"
                            show
                        />
                    ) : (
                        <div className={s.player}>
                            <img src={Image1} alt="" />
                            <div
                                className={s.planBtn}
                                onClick={() => {
                                    localStorage.setItem(ONBOARDING_WATCHED_INTRO_VIDEO, 'true');
                                    setHasWatchedVideo(true);
                                    setPlay(true);
                                }}
                            />
                        </div>
                    )
                }
            </div>
            <div className={s.content}>
                <div className={s.title}>Welcome to Kiwi Health – built for providers like you</div>
                <div className={s.desc}>Ready to grow your practice and connect with more patients? Kiwi Health makes it easy to manage your online presence and boost visibility across key medical directories. Watch this quick video to see how we can help your practice succeed!</div>
            </div>
            <div className={s.footer}>
                <div className={s.step}>Step 1 of 5</div>
                <div className={s.btns}>
                    <Button style={{ width: '120px' }} type="primary" onClick={handleNext}>Next</Button>
                </div>
            </div>
        </div>
    );
};

export default Intro;
