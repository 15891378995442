import React from 'react';
import s from './s.module.less';
import { Button } from 'antd';

const InitStep: React.FC<{
    className: string;
    onClick?: () => void;
}> = ({ className, onClick }) => {
    const handleClick = () => {
        onClick?.();
        window.open('https://www.youtube.com/watch?v=reb_QeK5TVw', '_blank');
    };
    return (
        <div className={className}>
            <div className={s.content}>
                <p>
                    Ready to grow your practice and connect with more patients? Kiwi Health makes it easy to manage your online presence and boost visibility across key medical directories. Watch this quick video to see how we can help your practice succeed!
                </p>
            </div>
            <div className={s.buttonWrap}>
                <Button type="primary" onClick={handleClick}>Watch video</Button>
            </div>
        </div>
    );
};

export default InitStep;
