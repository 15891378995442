// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/home/play.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__videoLink--Ijs2B {
  text-decoration: underline;
  color: var(--main-green);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-left: 16px;
}
.s-module__videoLink--Ijs2B::before {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 0 0 no-repeat;
  background-size: 100% auto;
  width: 24px;
  height: 24px;
  content: '';
  margin-right: 4px;
}
.s-module__videoLink--Ijs2B:hover {
  opacity: 0.8;
  color: var(--main-green);
}
.s-module__button--N8E1L {
  background-color: #fff;
  border-radius: 8px;
}
.s-module__button--N8E1L :where(.css-1uhj8f6).ant-btn-default.ant-btn-background-ghost {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.s-module__button--N8E1L .ant-btn-default.ant-btn-background-ghost {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.s-module__button--N8E1L :where(.css-dev-only-do-not-override-1uhj8f6).ant-btn-default.ant-btn-background-ghost {
  border-color: var(--gray-300);
  color: var(--gray-500);
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProviderHomePage/components/WatchVideo/s.module.less"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,wBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;AACF;AACE;EACE,iEAAA;EACA,0BAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;AACJ;AAEE;EACE,YAAA;EACA,wBAAA;AAAJ;AAIA;EACE,sBAAA;EACA,kBAAA;AAFF;AAAA;EAMM,kCAAA;EACA,2BAAA;AAHN;AAJA;EAWM,kCAAA;EACA,2BAAA;AAJN;AARA;EAgBM,6BAAA;EACA,sBAAA;AALN","sourcesContent":[".videoLink {\n  text-decoration: underline;\n  color: var(--main-green);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  display: flex;\n  align-items: center;\n  margin-left: 16px;\n\n  &::before {\n    background: url(assets/home/play.svg) 0 0 no-repeat;\n    background-size: 100% auto;\n    width: 24px;\n    height: 24px;\n    content: '';\n    margin-right: 4px;\n  }\n\n  &:hover {\n    opacity: .8;\n    color: var(--main-green);\n  }\n}\n\n.button {\n  background-color: #fff;\n  border-radius: 8px;\n\n  :global {\n    :where(.css-1uhj8f6).ant-btn-default.ant-btn-background-ghost {\n      border-color: var(--primary-color);\n      color: var(--primary-color);\n    }\n\n    .ant-btn-default.ant-btn-background-ghost {\n      border-color: var(--primary-color);\n      color: var(--primary-color);\n    }\n\n    :where(.css-dev-only-do-not-override-1uhj8f6).ant-btn-default.ant-btn-background-ghost {\n      border-color: var(--gray-300);\n      color: var(--gray-500)\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoLink": `s-module__videoLink--Ijs2B`,
	"button": `s-module__button--N8E1L`
};
export default ___CSS_LOADER_EXPORT___;
