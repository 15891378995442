// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__progressContainer--IU87p {
  position: relative;
  width: 32px;
  height: 32px;
}
.s-module__progressContainer--IU87p svg {
  transform: rotate(-90deg);
}
.s-module__progressCircle--J02xf {
  fill: none;
  stroke: #8E9CFF;
  stroke-width: 2;
}
.s-module__progressBar--N_vtA {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: 100;
  transition: stroke-dashoffset 0.5s ease;
}
.s-module__progressText--Le3LB {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 8px;
  font-weight: bold;
  color: #FFFFFF;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProviderHomePage/components/OnboardingPopups/progress/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;AACJ;AAJA;EAMQ,yBAAA;AACR;AAGA;EACI,UAAA;EACA,eAAA;EACA,eAAA;AADJ;AAIA;EACI,UAAA;EACA,eAAA;EACA,eAAA;EACA,qBAAA;EACA,qBAAA;EACA,uCAAA;AAFJ;AAKA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,cAAA;EACA,iBAAA;EACA,cAAA;AAHJ","sourcesContent":[".progressContainer {\n    position: relative;\n    width: 32px;\n    height: 32px;\n\n    svg {\n        transform: rotate(-90deg);\n    }\n}\n\n.progressCircle {\n    fill: none;\n    stroke: #8E9CFF;\n    stroke-width: 2;\n}\n\n.progressBar {\n    fill: none;\n    stroke: #FFFFFF;\n    stroke-width: 4;\n    stroke-linecap: round;\n    stroke-dasharray: 100;\n    transition: stroke-dashoffset 0.5s ease;\n}\n\n.progressText {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 8px;\n    font-weight: bold;\n    color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressContainer": `s-module__progressContainer--IU87p`,
	"progressCircle": `s-module__progressCircle--J02xf`,
	"progressBar": `s-module__progressBar--N_vtA`,
	"progressText": `s-module__progressText--Le3LB`
};
export default ___CSS_LOADER_EXPORT___;
