import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { message, Modal, Spin } from 'antd';
import { CALENDLY_LINK } from 'constants/common';
import { Provider } from 'types/provider';
import isEmpty from 'lodash/isEmpty';
import { calendlyProviderSave } from 'api/common';
import { EWebinarScheduleType } from 'types/common';

interface IProps {
    onClose: () => void;
    isHeadway?: boolean;
    provider: Provider;
    onBoardingCanlendlyScheduled: () => void;
}

const CalendlyPopup = ({
    onClose,
    isHeadway,
    provider,
    onBoardingCanlendlyScheduled,
}: IProps) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const HOST = isHeadway ? CALENDLY_LINK.ONBOARDING : CALENDLY_LINK.INTRODUCTION_TO_KIWI;

        setTimeout(() => {
            const objString = new URLSearchParams({
                hide_gdpr_banner: '1',
                first_name: provider?.firstName,
                last_name: provider?.lastName,
            }).toString();

            (window as any).Calendly.initInlineWidget({
                url: `${HOST}?${objString}`,
                // url: `https://calendly.com/kunqian-fan/11?${objString}`,
                parentElement: document.getElementById('calendly'),
                prefill: {
                    email: provider?.email,
                },
            });
        }, 300);
    }, [isHeadway, provider]);

    const calendlySuccessRequest = useCallback(async () => {
        try {
            setLoading(true);
            const res = await calendlyProviderSave(EWebinarScheduleType.ONBOARDING);

            if (res && !res.error) {
                setLoading(false);
                onBoardingCanlendlyScheduled();
            } else {
                message.error(res?.error());
            }
        } catch (e) {
            console.error(e);
        }
    }, [onBoardingCanlendlyScheduled]);

    useEffect(() => {
        const isCalendlyEvent = (e: any) => {
            return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
        };

        const eventCallback = (e: any) => {
            console.info('eventCallback', e);
            if (isCalendlyEvent(e)) {
                const calendlyEvent = e.data.event;
                const calendlyPayload = e.data.payload;
                console.info('calendlyEvent', calendlyEvent);
                if (calendlyEvent === 'calendly.event_type_viewed') {
                    setLoading(false);
                }

                if (calendlyEvent === 'calendly.event_scheduled') {
                    calendlySuccessRequest();
                }

                /**
                     * {
                     *  event: {
                     *      uri: "https://api.calendly.com/scheduled_events/32c365b7-2cb5-46d0-8051-ed54650fdae0"
                     *  },
                     *  invitee: {
                     *      uri: "https://api.calendly.com/scheduled_events/dc99e2df-52c9-4267-95cc-7151a12be01a/invitees/6a722e26-3bd2-438d-812f-54a137ccaa19"
                     * }
                     * }
                     */
                if (!isEmpty(calendlyPayload)) {
                    console.info('calendlyPayload', calendlyPayload);
                }
            }
        };

        window.addEventListener('message', eventCallback);

        return () => {
            window.removeEventListener('message', eventCallback);
        };
    }, [calendlySuccessRequest]);

    return (
        <div className={s.diymodal}>
            <Modal
                open
                width="75vw"
                style={{ padding: 0 }}
                footer={null}
                onCancel={onClose}
            >
                <div className={s.calendlyWrap}>
                    <Spin spinning={loading} />
                    <div id="calendly" className={s.calendlyWrap} />
                </div>
            </Modal>
        </div>
    );
};

export default CalendlyPopup;
