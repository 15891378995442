import React from 'react';
import s from './s.module.less';
import { Button, Modal } from 'antd';
import Image2 from './images/2.png';

interface IProps {
    setOnboardingPopupStep: (step: number) => void;
    onClosePopup: () => void;
    onShowCalendly: () => void;
}

const Webinar = ({
    setOnboardingPopupStep,
    onClosePopup,
    onShowCalendly,
}: IProps) => {
    return (
        <div className={s.wrap}>
            <div className={s.playerBox}>
                <div className={s.closeIcon} onClick={onClosePopup} />
                <div className={s.player}>
                    <img src={Image2} alt="" />
                </div>
            </div>
            <div className={s.content}>
                <div className={s.title}>Schedule webinar – learn how Kiwi works for you</div>
                <div className={s.desc}>Discover how Kiwi can help you grow your patient leads, improve your online presence, and make practice management easier.</div>
                <Button className={s.stepDirBtn} onClick={onShowCalendly}>Schedule now</Button>
            </div>
            <div className={s.footer}>
                <div className={s.step}>Step 2 of 5</div>
                <div className={s.btns}>
                    <Button style={{ width: '120px' }} onClick={() => setOnboardingPopupStep(1)}>Previous</Button>
                    <Button style={{ width: '120px' }} type="primary" onClick={() => setOnboardingPopupStep(3)}>Next</Button>
                </div>
            </div>
        </div>
    );
};

export default Webinar;
