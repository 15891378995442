import React, { useState } from 'react';
import s from './s.module.less';
import YouTubePlayer from 'components/YoutubePlayer';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import Image3 from './images/3.png';

interface IProps {
    setOnboardingPopupStep: (step: number) => void;
    onClosePopup: () => void;
}

const CreateUniprofile = ({
    setOnboardingPopupStep,
    onClosePopup,
}: IProps) => {
    const navigate = useNavigate();
    const [play, setPlay] = useState(false);

    return (
        <div className={s.wrap}>
            <div className={s.playerBox}>
                <div className={s.closeIcon} onClick={onClosePopup} />
                {
                    play ? (
                        <YouTubePlayer
                            playerWrapClassName={s.playerWrapper}
                            videoId="nXXvhwEWFfY"
                            show
                        />
                    ) : (
                        <div className={s.player}>
                            <img src={Image3} alt="" />
                            <div className={s.planBtn} onClick={() => setPlay(true)} />
                        </div>
                    )
                }
            </div>
            <div className={s.content}>
                <div className={s.title}>Create your UniProfile - stand out on Kiwi</div>
                <div className={s.desc}>Your UniProfile is your digital identity that helps patients discover and choose you. Watch our tutorial to learn how to create an engaging profile that attracts more patients!</div>
                <Button className={s.stepDirBtn} onClick={() => navigate(`/${PATH.UNIPROFILE_RESUME}?type=uniprofile`)}>Create UniProfile</Button>
            </div>
            <div className={s.footer}>
                <div className={s.step}>Step 3 of 5</div>
                <div className={s.btns}>
                    <Button style={{ width: '120px' }} onClick={() => setOnboardingPopupStep(2)}>Previous</Button>
                    <Button style={{ width: '120px' }} type="primary" onClick={() => setOnboardingPopupStep(4)}>Next</Button>
                </div>
            </div>
        </div>
    );
};

export default CreateUniprofile;
