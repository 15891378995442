import WebinarBooking from 'components/WebinarBooking';
import React from 'react';
import { EWebinarScheduleType } from 'types/common';
import s from './s.module.less';

type TProps = {
  title?: string,
  des?: string,
  link?: string,
  scheduleType?: EWebinarScheduleType,
  onScheduled?: () => void,
}

const ScheduleBanner = (props: TProps) => {
  const { title, des, link, scheduleType, onScheduled } = props;
  const [showWebinar, setShowWebinar] = React.useState(false);
  if (!title || !des || !link) {
    return null;
  }

  return (
    <div className={s.wrap}>
      <div className={s.icon}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M8.25706 3.0976C9.02167 1.7383 10.9788 1.7383 11.7434 3.0976L17.3237 13.0181C18.0736 14.3514 17.1102 15.9987 15.5805 15.9987H4.4199C2.89025 15.9987 1.92682 14.3514 2.67675 13.0181L8.25706 3.0976ZM11.0001 12.9988C11.0001 13.5511 10.5524 13.9988 10.0001 13.9988C9.44784 13.9988 9.00012 13.5511 9.00012 12.9988C9.00012 12.4465 9.44784 11.9988 10.0001 11.9988C10.5524 11.9988 11.0001 12.4465 11.0001 12.9988ZM10.0001 4.99878C9.44784 4.99878 9.00012 5.44649 9.00012 5.99878V8.99878C9.00012 9.55106 9.44784 9.99878 10.0001 9.99878C10.5524 9.99878 11.0001 9.55106 11.0001 8.99878V5.99878C11.0001 5.44649 10.5524 4.99878 10.0001 4.99878Z" fill="#FBBF24" />
        </svg>
      </div>
      <div className={s.text}>
        <div className={s.title}>{title}</div>
        <div className={s.des}>
          {des}
        </div>
      </div>
      <div
        onClick={() => {
          setShowWebinar(true);
        }}
        className={s.btn}>
        Schedule now
      </div>
      <WebinarBooking
        show={showWebinar}
        link={link}
        webinarScheduleType={scheduleType}
        onClose={(isSuccess) => {
          if (isSuccess) {
            onScheduled?.()
          }
          setShowWebinar(false);
        }} />
    </div>
  );
};

export default ScheduleBanner;
