import React from 'react';
import { Button } from 'antd';
import s from './s.module.less';

const InitStep: React.FC<{
    className: string;
    onClick?: () => void;
}> = ({ className, onClick }) => {
    return (
        <div className={className}>
            <div className={s.content}>
                <p>
                    Discover how Kiwi can help you grow your patient leads, improve your online presence, and make practice management easier.
                </p>
            </div>
            <div className={s.buttonWrap}>
                <Button
                    type="primary"
                    onClick={() => {
                        onClick?.();
                    }}
                >
                    Schedule now
                </Button>
            </div>
        </div>
    );
};

export default InitStep;
