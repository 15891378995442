import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChannelCard from './components/ChannelCard';
import s from './s.module.less';
import { useRequest } from 'ahooks';
import { getLiveChannelList } from 'api/channel';
import { CALENDLY_TYPE, Channel, ChannelItem, EWebinarScheduleType } from 'types/common';
import { message, Modal, Spin, Tabs } from 'antd';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import useHomeConfig from 'hooks/useHomeConfig';
import type { TabsProps } from 'antd';
import KlarityInitStep1 from './components/KlarityInitStep1';
import KlarityInitStep2 from './components/KlarityInitStep2';
import KlarityInitStep3 from './components/KlarityInitStep3';
import KiwiInitStep1 from './components/KiwiInitStep1';
import KiwiInitStep2 from './components/KiwiInitStep2';
import KiwiInitStep3 from './components/KiwiInitStep3';
import KiwiInitStep4 from './components/KiwiInitStep4';
import KiwiInitStep5 from './components/KiwiInitStep5';
import PaymentStatus from 'components/PaymentStatus';
import AgreementStatus from './components/AgreementStatus';
import HomeStore from 'store/Home';
import {
    CALENDLY_LINK,
    IS_BACK_FROM_STRIPE,
    ONBOARDING_WATCHED_INTRO_VIDEO,
    SHOW_KLARITY_ADVERTISING_ICON,
} from 'constants/common';
import {
    openKlarityPlanAdvertisingModal,
    openKlaritySubscriptionModal,
    openUniprofileAgreementSignModal,
} from 'utils/globalLayerControl';
import SubscriptionStore from 'store/Subscription';
import { CouponType, Provider } from 'types/provider';
import CommonStore from 'store/Common';
import ExploreKiwiHealth from './components/ExploreKiwiHealth';
import { PATH } from 'constants/path';
import { setSubscriptionCallbackPath, setHasShowAutoOnboardingPopup, getHasShowAutoOnboardingPopup } from 'utils/localstore';
import { isFreeUserByInfo } from 'utils/provider';
import GrowthHub from './components/GrowthHub';
import Schedule from 'pages/SalesProviderExp/components/Schedule';
import ScheduleBanner from './components/ScheduleBanner';
import { EProfileStatus } from 'types/channel';
import WebinarBooking from 'components/WebinarBooking';
import IntroPopup from './components/OnboardingPopups/Intro';
import WebinarPopup from './components/OnboardingPopups/Webinar';
import CreateUniprofilePopup from './components/OnboardingPopups/CreateUniprofile';
import GetPlanPopup from './components/OnboardingPopups/GetPlan';
import ExploreChannelPopup from './components/OnboardingPopups/ExploreChannel';
import CalendlyPopup from './components/OnboardingPopups/Calendly';
import ProviderStore from 'store/Provider';
import Alert from 'components/Alert';
import { getHasShowWebinarPopInCurrentSession, setHasShowWebinarPopInCurrentSession } from 'utils/sessionStorage';
import { isLocalhost } from 'utils/common';
import Progress from './components/OnboardingPopups/progress';
import GlobalLayerStore from 'store/GlobalLayer';
import { ESource } from 'types/operation';

const ProviderHomePage = () => {
    const [getGlobalLayer] = GlobalLayerStore.useStore();
    const hideOtherModalsForAgreementSignModalEnabled = getGlobalLayer('hideOtherModalsForAgreementSignModal');
    const [getUser] = ProviderStore.useStore();
    const providerData = getUser('data');
    const [onboardingPopupStep, setOnboardingPopupStep] = useState(-1);
    const [getCommonStore, setCommonStore] = CommonStore.useStore();
    const [, contextHolder] = message.useMessage();
    const { data: channelListData = { data: { data: [] } } } =
        useRequest(getLiveChannelList) || {};
    let listData = channelListData?.data?.data?.filter((item: Channel) => {
        return item.listStatus === 'not-listed';
    });
    listData?.sort((a: ChannelItem, b: ChannelItem) => {
        if (a.top) {
            return -1;
        }
        if (b.top) {
            return 1;
        }
        return 0;
    });
    listData = listData?.slice(0, 6);
    const [data, loadingHome, fetchData] = useProviderHomeInfo();
    const [getHomeStore] = HomeStore.useStore();
    const showKlarityAdvertisingIcon = getCommonStore('showAdvertisingIcon');
    const [configList, loadingConfig] = useHomeConfig();
    const [currentInx, setCurrentInx] = useState(0);
    const itemList = configList || [];
    const currentItem = itemList?.[currentInx] || {
        title: '',
        headLine: '',
        content: '',
    };
    const practiceFrontCompleted = !!data?.practiceFrontCompleted;
    const uniprofileApproved = !!data?.uniprofileApproved;
    const uniprofileSubmitted = !!data?.uniprofileSubmitted;
    const uniprofileApproveCompleted = !!data?.uniprofileApproveCompleted;
    const ehrSetUpCompleted = !!data?.ehrSetUpCompleted;
    const agreementReviewTypeList = data?.agreementReviewTypeList;
    const klarityUserWithoutKiwiPlan = !!data?.klarityUserWithoutKiwiPlan;
    const klarityPlanOnboardingDone = !!data?.klarityStarterSetUpCompleted;
    const uniprofileStatus = data?.uniprofileStatus;
    const firstUniprofileApprovedAt = data?.firstUniprofileApprovedAt;
    const firstUniprofileSubmitAt = data?.firstUniprofileSubmitAt;
    const firstPracticeLaunchAt = data?.firstPracticeLaunchAt;
    const ehrSetUpCompletedAt = data?.ehrSetUpCompletedAt; //用户可能没有选择addon，导致之前的bug是这一步显示没有完成，但是后续步骤都完成了。所以如果用户没有选addon，也就是没有addpaid时间，那么就用这个时间作为完成时间以及第三步骤的过期计算的开始时间
    const firstAddOnPaidAt = data?.firstAddOnPaidAt || ehrSetUpCompletedAt;
    const addOnPaid = !!data?.firstAddOnPaidAt || !!ehrSetUpCompletedAt;
    const isKlarity = !!data?.klarityUser;
    const isHeadwayUser = !!(data?.signUpFrom === ESource.HEADWAY);
    const completedOnboarding = !!data?.completedOnboarding;
    const firstLoginAt = data?.firstLoginAt;
    const providerCalendlyScheduleRecords = data?.providerCalendlyScheduleRecords || [];
    const [activeKey, setActiveKey] = useState('');
    const shouldRefetch = getHomeStore('reload');
    const payPlan = SubscriptionStore.get('currentPlan');
    const isFreeUser = payPlan && isFreeUserByInfo(payPlan);
    const showFreeUserStep = isFreeUser && !isKlarity;
    const [finishedStep, setFinishedStep] = useState(0);
    const [showOnboardingCalendlyPopup, setShowOnboardingCalendlyPopup] = useState(false);
    const [showOnboardingCalendlyScheduled, setShowOnboardingCalendlyScheduled] = useState(false);
    const [showWebinarPop, setShowWebinarPop] = useState(false);
    const [showWebinarBanner, setShowWebinarBanner] = useState(false);
    const [webinarLink, setWebinarLink] = useState('');
    const [webinarTitle, setWebinarTitle] = useState('');
    const [webinarDes, setWebinarDes] = useState('');
    const [webinarScheduleType, setWebinarScheduleType] = useState<EWebinarScheduleType>();
    const [onboardingCompletedPercent, setOnboardingCompletedPercent] = useState(100);
    const [hasWatchedVideo, setHasWatchedVideo] = useState(false);
    const [hasScheduledOnboardingWebinar, setHasScheduledOnboardingWebinar] = useState(false);

    React.useEffect(() => {
        if (data && payPlan) {
            const _isKlarity = !!data?.klarityUser;
            let _webinarLink = CALENDLY_LINK.LEARN_ABOUT_UNIPROFILE;
            let _webinarTitle = 'Schedule "Learn about UniProfile webinar"';
            let _webinarDes = 'Your UniProfile hasn’t been submitted yet. Schedule the “Learn about UniProfile webinar” to get step-by-step assistance in completing your profile and ensuring it’s ready for channel listings.';
            let _webinarScheduleType = EWebinarScheduleType.LEARN_ABOUT_UNIPROFILE;
            //1 UniProfile: Not Submit - Paid
            let showLearnAboutUniProfileWebinar = !data?.uniprofileSubmitted && !isFreeUser;
            const hasBookLearnAboutUniProfileWebinar = providerCalendlyScheduleRecords?.includes(EWebinarScheduleType.LEARN_ABOUT_UNIPROFILE);
            showLearnAboutUniProfileWebinar = showLearnAboutUniProfileWebinar && !hasBookLearnAboutUniProfileWebinar;

            //2 UniProfile: Not Submit - UnPaid
            let showOnboardingWebinar = !data?.uniprofileSubmitted && isFreeUser;
            const hasBookOnboardingWebinar = providerCalendlyScheduleRecords?.includes(EWebinarScheduleType.ONBOARDING);
            if (showOnboardingWebinar) {
                _webinarLink = CALENDLY_LINK.ONBOARDING;
                _webinarTitle = 'Schedule "Onboarding webinar"';
                _webinarDes = 'To unlock Kiwi’s full potential, schedule the webinar to walk through Kiwi’s features, complete your UniProfile, and set yourself up for success.';
                _webinarScheduleType = EWebinarScheduleType.ONBOARDING;
            }
            showOnboardingWebinar = showOnboardingWebinar && !hasBookOnboardingWebinar;

            //3 UniProfile: Submitted - Paid
            //5 UniProfile: Approved - Paid
            //7 UniProfile: Not Approved - Paid
            let showOptimizationWebinar = data?.uniprofileSubmitted && !isFreeUser;
            if (!showOptimizationWebinar) {
                showOptimizationWebinar = data?.uniprofileApproved && !isFreeUser;
            }
            if (showOptimizationWebinar) {
                _webinarTitle = 'Schedule "UniProfile optimization webinar"';
                _webinarDes = 'To finalize your profile, attend the essential webinar to ensure your information is complete and optimized for channel listings.';
                _webinarLink = CALENDLY_LINK.UNIPROFILE_OPTIMISATION;
                _webinarScheduleType = EWebinarScheduleType.UNIPROFILE_OPTIMISATION;
            }
            const hasBookOptimizationWebinar = providerCalendlyScheduleRecords?.includes(EWebinarScheduleType.UNIPROFILE_OPTIMISATION);
            showOptimizationWebinar = showOptimizationWebinar && !hasBookOptimizationWebinar;

            //6 UniProfile: Approved - UnPaid
            let showAccountActivationWebinar = data?.uniprofileApproved && isFreeUser;
            if (showAccountActivationWebinar) {
                _webinarTitle = 'Schedule "Account activation webinar"';
                _webinarDes = 'Your UniProfile is complete! Schedule a webinar to discover Kiwi’s features, plans, and benefits — and ensure your UniProfile is fully complete.';
                _webinarLink = CALENDLY_LINK.ACCOUNT_ACTIVATION;
                _webinarScheduleType = EWebinarScheduleType.ACCOUNT_ACTIVATION;
            }
            const hasBookAccountActivationWebinar = providerCalendlyScheduleRecords?.includes(EWebinarScheduleType.ACCOUNT_ACTIVATION);
            showAccountActivationWebinar = showAccountActivationWebinar && !hasBookAccountActivationWebinar;

            //8 UniProfile: Not Approved - UnPaid
            //9 UniProfile: Submitted - UnPaid
            let showIntroductionToKiwiWebinar = data?.uniprofileSubmitted && !data.uniprofileApproved && isFreeUser;
            if (showIntroductionToKiwiWebinar) {
                _webinarTitle = 'Schedule "Introduction to Kiwi webinar"';
                _webinarDes = 'To finalize your profile, attend the essential webinar to ensure your information is complete and optimized for channel listings.';
                _webinarLink = CALENDLY_LINK.INTRODUCTION_TO_KIWI;
                _webinarScheduleType = EWebinarScheduleType.INTRODUCTION_TO_KIWI;
            }
            const hasBookIntroductionToKiwiWebinar = providerCalendlyScheduleRecords?.includes(EWebinarScheduleType.INTRODUCTION_TO_KIWI);
            showIntroductionToKiwiWebinar = showIntroductionToKiwiWebinar && !hasBookIntroductionToKiwiWebinar;

            //!isKlarity && data?.uniprofileApproved || !data?.uniprofileSubmitted || !!isFreeUser
            const _showWebinarBanner = !_isKlarity && (showLearnAboutUniProfileWebinar || showOnboardingWebinar || showOptimizationWebinar || showAccountActivationWebinar || showIntroductionToKiwiWebinar);
            let _showWebinarPop = _showWebinarBanner;

            if (getHasShowWebinarPopInCurrentSession()) {
                _showWebinarPop = false;
            }
            setWebinarTitle(_webinarTitle);
            setWebinarDes(_webinarDes);
            setWebinarLink(_webinarLink);
            setWebinarScheduleType(_webinarScheduleType);
            setShowWebinarPop(!!_showWebinarPop);
            setShowWebinarBanner(!!_showWebinarBanner);
            if (_showWebinarPop) {
                setHasShowWebinarPopInCurrentSession(true);
            }
        }
    }, [isFreeUser, data, payPlan]);

    useEffect(() => {
        const showAdvertisingIconLS = localStorage.getItem(
            SHOW_KLARITY_ADVERTISING_ICON,
        );
        if (
            isFreeUser &&
            klarityUserWithoutKiwiPlan &&
            showAdvertisingIconLS !== '1' &&
            showAdvertisingIconLS !== '2'
        ) {
            // if (isFreeUser && showAdvertisingIconLS !== '1' && showAdvertisingIconLS !== '2') {

            localStorage.setItem(SHOW_KLARITY_ADVERTISING_ICON, '1');
            setCommonStore('showAdvertisingIcon', '1');
            //removed on 08/28
        }
    }, [
        showKlarityAdvertisingIcon,
        isFreeUser,
        klarityUserWithoutKiwiPlan,
        setCommonStore,
        getCommonStore,
    ]);

    useEffect(() => {
        if (typeof shouldRefetch === 'boolean' && shouldRefetch) {
            fetchData();
        }
    }, [shouldRefetch]);

    useEffect(() => {
        const isBackFromStripe = sessionStorage.getItem(IS_BACK_FROM_STRIPE);
        if (
            isBackFromStripe !== 'true' &&
            agreementReviewTypeList &&
            agreementReviewTypeList.length > 0
        ) {
            openUniprofileAgreementSignModal('Confirm agreement of service', false);
        }
    }, [agreementReviewTypeList]);

    useEffect(() => {
        let hasDone = false;
        if (uniprofileSubmitted || !isFreeUser || practiceFrontCompleted) {
            hasDone = true;
        }
        hasDone = !!(data?.providerCalendlyScheduleRecords?.includes(CALENDLY_TYPE.ONBOARDING));

        setHasScheduledOnboardingWebinar(hasDone);
    }, [data, uniprofileSubmitted, isFreeUser, practiceFrontCompleted]);

    useEffect(() => {
        let hasDone = false;
        if (hasScheduledOnboardingWebinar || uniprofileSubmitted || !isFreeUser || practiceFrontCompleted) {
            hasDone = true;
        }

        hasDone = localStorage.getItem(ONBOARDING_WATCHED_INTRO_VIDEO) === 'true';
        setHasWatchedVideo(hasDone);
    }, [hasScheduledOnboardingWebinar, uniprofileSubmitted, isFreeUser, practiceFrontCompleted]);

    const completedOnboardingSteps = useMemo(() => {
        return [hasWatchedVideo, hasScheduledOnboardingWebinar, uniprofileSubmitted, !isFreeUser, practiceFrontCompleted];
    }, [hasWatchedVideo, hasScheduledOnboardingWebinar, uniprofileSubmitted, isFreeUser, practiceFrontCompleted]);

    // uniprofileSubmitted, isFreeUser, practiceFrontCompleted
    useEffect(() => {
        setOnboardingCompletedPercent(
            (completedOnboardingSteps.filter((e) => e).length / completedOnboardingSteps.length) * 100,
        );
    }, [completedOnboardingSteps]);

    const handleContinueToOnBoarding = useCallback(() => {
        const uncompletedStep = completedOnboardingSteps.findIndex((e) => !e);
        setOnboardingPopupStep((uncompletedStep || uncompletedStep === 0) ? uncompletedStep + 1 : -1);
    }, [completedOnboardingSteps]);

    useEffect(() => {
        if (!getHasShowAutoOnboardingPopup()) {
            handleContinueToOnBoarding();
            setHasShowAutoOnboardingPopup('true');
        }
    }, [handleContinueToOnBoarding]);

    const spinning = loadingHome || loadingConfig;

    const kiwiItems: TabsProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className={`${s.kiwiTabText} ${hasWatchedVideo ? s.tabCompleted : ''
                    }`}
                >
                    Watch intro video
                </div>
            ),
            children: (
                <KiwiInitStep1
                    className={s.step}
                    onClick={() => {
                        setHasWatchedVideo(true);
                        localStorage.setItem(ONBOARDING_WATCHED_INTRO_VIDEO, 'true');
                    }}
                />
            ),
        },
        {
            key: '2',
            label: (
                <div
                    className={`${s.kiwiTabText} ${hasScheduledOnboardingWebinar ? s.tabCompleted : ''
                    }`}
                >
                    Schedule a webinar
                </div>
            ),
            children: (
                <KiwiInitStep2
                    className={s.step}
                    onClick={() => setShowOnboardingCalendlyPopup(true)}
                />
            ),
        },
        {
            key: '3',
            label: (
                <div
                    className={`${s.kiwiTabText} ${uniprofileSubmitted ? s.tabCompleted : ''
                    }`}
                >
                    Create your UniProfile
                </div>
            ),
            children: (
                <KiwiInitStep3
                    className={s.step}
                    isSubmittedUniproile={uniprofileSubmitted}
                />
            ),
        },
        {
            key: '4',
            label: (
                <div
                    className={`${s.kiwiTabText} ${!isFreeUser ? s.tabCompleted : ''
                    }`}
                >
                    Upgrade to Growth plan
                </div>
            ),
            children: <KiwiInitStep4 className={s.step} />,
        },
        {
            key: '5',
            label: (
                <div
                    className={`${s.kiwiTabText} ${practiceFrontCompleted ? s.tabCompleted : ''
                    }`}
                >
                    Set up your Practice Front and list on multiple channels
                </div>
            ),
            children: <KiwiInitStep5 className={s.step} />,
        },
    ];

    const klarityItems = [
        {
            key: '1',
            label: (
                <div
                    className={`${s.klarityTabText} ${firstUniprofileSubmitAt ? s.tabCompleted : ''
                    }`}
                >
                    Complete UniProfile
                </div>
            ),
            // disabled: uniprofileApproveCompleted,
            children: (
                <KlarityInitStep1
                    firstUniprofileSubmitAt={firstUniprofileSubmitAt}
                    uniprofileStatus={uniprofileStatus}
                    firstLoginAt={firstLoginAt}
                    className={s.step}
                    isSubmittedUniproile={uniprofileSubmitted}
                />
            ),
        },
        klarityUserWithoutKiwiPlan
            ? {
                key: '2',
                label: (
                    <div
                        className={`${s.klarityTabText} ${addOnPaid ? s.tabCompleted : ''
                        }`}
                    >
                        Set up your practice
                    </div>
                ),
                // disabled: ehrSetUpCompleted && addOnPaid,
                children: (
                    <ExploreKiwiHealth
                        firstAddOnPaidAt={firstAddOnPaidAt}
                        uniprofileStatus={uniprofileStatus}
                        addOnPaid={addOnPaid}
                        firstUniprofileSubmitAt={firstUniprofileSubmitAt}
                        uniprofileSubmitted={uniprofileSubmitted}
                        ehrSetUpCompleted={ehrSetUpCompleted}
                    />
                ),
            }
            : undefined,
        {
            key: '3',
            label: (
                <div
                    className={`${s.klarityTabText} ${practiceFrontCompleted ? s.tabCompleted : ''
                    }`}
                >
                    Explore Kiwi Health
                </div>
            ),
            children: (
                <KlarityInitStep2
                    firstAddOnPaidAt={firstAddOnPaidAt}
                    addOnPaid={addOnPaid}
                    uniprofileStatus={uniprofileStatus}
                    firstPracticeLaunchAt={firstPracticeLaunchAt}
                    uniprofileApproveCompleted={uniprofileApproveCompleted}
                    className={s.step}
                    flag={uniprofileApproveCompleted && !ehrSetUpCompleted}
                    isEhrSetUpCompleted={ehrSetUpCompleted}
                    practiceFrontCompleted={practiceFrontCompleted}
                />
            ),
        },
        // {
        //     key: '4',
        //     label: 'Explore practice enablement add-ons',
        //     children: (
        //         <KlarityInitStep3 className={s.step} flag={ehrSetUpCompleted} />
        //     ),
        // },
    ].filter((e) => !!e);

    useEffect(() => {
        if (isKlarity) {
            if (!uniprofileSubmitted) {
                setActiveKey('1');
                setFinishedStep(0);
                return;
            }
            if ((!ehrSetUpCompleted || !addOnPaid) && klarityUserWithoutKiwiPlan) {
                setActiveKey('2');
                setFinishedStep(1);
                return;
            }
            if (uniprofileSubmitted && ehrSetUpCompleted && addOnPaid) {
                setActiveKey('3');
                setFinishedStep(2);
            }

            if (practiceFrontCompleted) {
                setFinishedStep(3);
            }
        } else {
            if (!hasWatchedVideo) {
                setActiveKey('1');
                return;
            }
            if (!hasScheduledOnboardingWebinar) {
                setActiveKey('2');
                return;
            }
            if (!uniprofileSubmitted) {
                setActiveKey('3');
                return;
            }

            if (isFreeUser) {
                setActiveKey('4');
                return;
            }
            if (!practiceFrontCompleted) {
                setActiveKey('5');
                return;
            }
            setActiveKey('1');
        }
    }, [
        isKlarity,
        ehrSetUpCompleted,
        uniprofileApproveCompleted,
        uniprofileApproved,
        practiceFrontCompleted,
        uniprofileSubmitted,
        klarityUserWithoutKiwiPlan,
        isFreeUser,
        addOnPaid,
        hasScheduledOnboardingWebinar,
        hasWatchedVideo,
    ]);

    const handleChangeTab = (key: string) => {
        setActiveKey(key);
    };

    const renderInit = () => {
        return (
            <div className={s.KlarityInitWrap}>
                <h2 className={s.initTitle}>
                    {isKlarity
                        ? `Setup checklist for your Klarity practice (${finishedStep}/3 completed)`
                        : 'Get started'}
                </h2>
                <Tabs
                    className={s.tabBorder}
                    activeKey={activeKey}
                    onChange={(currentKey) => handleChangeTab(currentKey)}
                    tabPosition="left"
                    items={isKlarity ? (klarityItems as TabsProps['items']) : kiwiItems}
                    animated={{ inkBar: false, tabPane: false }}
                />
            </div>
        );
    };

    const onboardingPopupRender = useMemo(() => {
        if (isKlarity || loadingHome || onboardingCompletedPercent === 100 || hideOtherModalsForAgreementSignModalEnabled) {
            return null;
        }

        let content = null;
        const commomProps = {
            setOnboardingPopupStep,
            onClosePopup: () => {
                setOnboardingPopupStep(-1);
            },
        };
        if (onboardingPopupStep === 1) {
            content = <IntroPopup {...commomProps} setHasWatchedVideo={setHasWatchedVideo} />;
        }
        if (onboardingPopupStep === 2) {
            content = <WebinarPopup {...commomProps} onShowCalendly={() => setShowOnboardingCalendlyPopup(true)} />;
        }
        if (onboardingPopupStep === 3) {
            content = <CreateUniprofilePopup {...commomProps} />;
        }
        if (onboardingPopupStep === 4) {
            content = <GetPlanPopup {...commomProps} />;
        }
        if (onboardingPopupStep === 5) {
            content = <ExploreChannelPopup {...commomProps} />;
        }

        return content && (
            <Modal
                className={s.onboardingModal}
                open
                width={534}
                footer={null}
                closable={false}
            >
                {content}
            </Modal>
        );
    }, [isKlarity, loadingHome, onboardingCompletedPercent, hideOtherModalsForAgreementSignModalEnabled, onboardingPopupStep]);

    const onCloseWebinarPop = (isSuccess: boolean) => {
        setShowWebinarPop(false);
        if (isSuccess) {
            fetchData();
        }
    };

    const onScheduled = () => {
        fetchData();
    };

    if (completedOnboarding) {
        return <GrowthHub
            showWebinarBanner={showWebinarBanner}
            webinarTitle={webinarTitle}
            webinarDes={webinarDes}
            webinarLink={webinarLink}
            webinarScheduleType={webinarScheduleType}
            onCloseWebinarPop={onCloseWebinarPop}
            showWebinarPop={showWebinarPop}
            onScheduled={onScheduled}
        />;
    }

    return (
        <Spin tip="Loading" size="large" spinning={spinning}>
            <div className={s.wrapper}>

                {
                    onboardingPopupRender
                }
                {!isKlarity && onboardingCompletedPercent !== 100 && (
                    <div className={s.onboardingProcess} onClick={handleContinueToOnBoarding}>
                        <div className={s.progressBar}>
                            <Progress value={onboardingCompletedPercent} />
                        </div>
                        Complete onboarding
                    </div>
                )
                }
                {
                    !isKlarity && showOnboardingCalendlyPopup && providerData && (
                        <CalendlyPopup
                            isHeadway={isHeadwayUser}
                            onClose={() => setShowOnboardingCalendlyPopup(false)}
                            provider={providerData as Provider}
                            onBoardingCanlendlyScheduled={() => {
                                setShowOnboardingCalendlyPopup(false);
                                setHasScheduledOnboardingWebinar(true);
                                setShowOnboardingCalendlyScheduled(true);
                            }}
                        />
                    )
                }
                <div className={s.inner}>
                    {contextHolder}
                    <div className={s.header}>
                        <div className={s.title}>Welcome 👋</div>
                    </div>
                    {showOnboardingCalendlyScheduled && <Alert
                        innerHtml="🎊 Webinar scheduled successfully! <br/> <span style='padding-left: 17px;'>Your session is confirmed! Be sure to attend your webinar on time to get the most out of Kiwi and grow your practice.</span>"
                        onClose={() => setShowOnboardingCalendlyScheduled(false)}
                    />}
                    <PaymentStatus />
                    {
                        payPlan?.couponType !== CouponType.HEADWAY &&
                        agreementReviewTypeList &&
                        agreementReviewTypeList.length > 0 && (
                            <div className={s.agreeWrap}>
                                <AgreementStatus fetchData={fetchData} />
                            </div>
                        )
                    }
                    {
                        showWebinarBanner && (
                            <div className={s.bannerWrap}>
                                <ScheduleBanner
                                    title={webinarTitle}
                                    des={webinarDes}
                                    link={webinarLink}
                                    scheduleType={webinarScheduleType}
                                    onScheduled={() => {
                                        fetchData();
                                    }}
                                />
                            </div>
                        )
                    }
                    <div className={s.initWrap}>
                        <div className={s.left}>
                            {renderInit()}
                        </div>
                        {!isKlarity && (
                            <div className={s.right}>
                                <div className={s.panel}>
                                    <div className={s.pTitle}>
                                        <h3>{currentItem.title}</h3>
                                        <div className={s.switch}>
                                            <span
                                                onClick={() => {
                                                    if (currentInx === 0) {
                                                        return;
                                                    }
                                                    setCurrentInx(currentInx - 1);
                                                }}
                                                className={
                                                    currentInx !== 0
                                                        ? `${s.sLeft} ${s.sLeftEnable}`
                                                        : s.sLeft
                                                }
                                            >
                                                {'<'}
                                            </span>
                                            <span className={s.val}>
                                                {currentInx + 1}/{itemList.length}
                                            </span>
                                            <span
                                                onClick={() => {
                                                    if (currentInx === itemList.length - 1) {
                                                        return;
                                                    }
                                                    setCurrentInx(currentInx + 1);
                                                }}
                                                className={
                                                    currentInx < itemList.length - 1
                                                        ? `${s.sRight} ${s.sRightEnable}`
                                                        : s.sRight
                                                }
                                            >
                                                {'>'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={s.pContentWrap}>
                                        <div
                                            className={s.pContentInner}
                                            style={{
                                                transform: `translateX(-${currentInx * 33.3}%)`,
                                            }}
                                        >
                                            {itemList?.map((item, inx) => {
                                                return (
                                                    <div
                                                        style={{ left: `${inx * 33.3}%` }}
                                                        className={s.pContent}
                                                        key={item.title}
                                                    >
                                                        <h3>{item.headLine}</h3>
                                                        <div className={s.pDes}>{item.content}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!(isKlarity && klarityUserWithoutKiwiPlan) && (
                        <div className={s.body}>
                            <h3>
                                Explore the most popular marketing channels for healthcare
                                practices
                            </h3>
                            <div className={s.rChannelWrap}>
                                {listData?.map((item: Channel) => {
                                    return (
                                        <div key={item.id} className={s.rChannelItem}>
                                            <ChannelCard key={item.id} item={item} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
                <WebinarBooking
                    modalHeader={(
                        <div className={s.wHeader}>
                            <div className={s.wTitle}>
                                <span className={s.wHigh}>!!</span>
                                Don’t miss out:
                                {webinarTitle?.replace('Schedule', 'schedule')}
                            </div>
                            <div className={s.wDes}>{webinarDes}</div>
                        </div>
                    )}
                    show={showWebinarPop}
                    link={webinarLink}
                    webinarScheduleType={webinarScheduleType}
                    onClose={(isSuccess: boolean) => {
                        setShowWebinarPop(false);
                        if (isSuccess) {
                            fetchData();
                        }
                    }}
                />
            </div>
        </Spin>
    );
};

export default ProviderHomePage;
