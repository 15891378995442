// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--yTYbp {
  padding: 16px;
  border-radius: 6px;
  background: var(--yellow-50, #FFFBEB);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: var(--yellow-800, #92400E);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.s-module__wrap--yTYbp .s-module__text--k5QJW {
  flex-grow: 1;
}
.s-module__wrap--yTYbp .s-module__icon--FS1sQ {
  flex-shrink: 0;
  margin-right: 16px;
}
.s-module__wrap--yTYbp .s-module__icon--FS1sQ svg {
  transform: rotate(0deg);
}
.s-module__wrap--yTYbp .s-module__des--WnxPL {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
}
.s-module__wrap--yTYbp .s-module__btn--tEVyb {
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--yellow-300, #FCD34D);
  background: var(--white, #FFF);
  /* shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  padding: 4px 6px;
}
.s-module__wrap--yTYbp .s-module__btn--tEVyb:hover {
  cursor: pointer;
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProviderHomePage/components/ScheduleBanner/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,qCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,iCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAAF;AAXA;EAcI,YAAA;AAAJ;AAdA;EAkBI,cAAA;EACA,kBAAA;AADJ;AAlBA;EAqBM,uBAAA;AAAN;AArBA;EA0BI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AAFJ;AA3BA;EAiCI,cAAA;EACA,kBAAA;EACA,4CAAA;EACA,8BAAA;EAHF,cAAc;EAKZ,+CAAA;EACA,gBAAA;AAHJ;AApCA;EA2CI,eAAA;EACA,YAAA;AAJJ","sourcesContent":[".wrap {\n  padding: 16px;\n  border-radius: 6px;\n  background: var(--yellow-50, #FFFBEB);\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n\n  color: var(--yellow-800, #92400E);\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n\n  .text {\n    flex-grow: 1;\n  }\n\n  .icon {\n    flex-shrink: 0;\n    margin-right: 16px;\n     svg {\n      transform: rotate(0deg);\n     }\n  }\n\n  .des {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    margin-top: 8px;\n  }\n\n  .btn {\n    flex-shrink: 0;\n    border-radius: 4px;\n    border: 1px solid var(--yellow-300, #FCD34D);\n    background: var(--white, #FFF);\n    /* shadow/sm */\n    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);\n    padding: 4px 6px;\n  }\n\n  .btn:hover {\n    cursor: pointer;\n    opacity: 0.8;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--yTYbp`,
	"text": `s-module__text--k5QJW`,
	"icon": `s-module__icon--FS1sQ`,
	"des": `s-module__des--WnxPL`,
	"btn": `s-module__btn--tEVyb`
};
export default ___CSS_LOADER_EXPORT___;
